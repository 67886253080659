import { NetworkHeader, NetworkListContainer, useNetworkList, NetworkListShimmer, UserFeedbackPopup, Constants } from "./Imports_NetworkListIndex";

const NetworkListIndex = ({ setOpenNetworkList, userCode, isLive, productionUrl, showUserFeedbackPopup, setShowUserFeedbackPopup, isShareCard }) => {
  let fetchNumber = window.innerHeight< 640? 10 : 15

  const { networkList, totalCount, page, loading, hasMore, netWorkListPagination,isPagination } = useNetworkList(isLive, productionUrl, userCode,fetchNumber)

  const networkListData = {
    networkList, totalCount, page, loading, hasMore, netWorkListPagination,isPagination
  }

  return (
    <>
    <div className='network-list'>
      <NetworkHeader setOpenNetworkList={setOpenNetworkList} setShowUserFeedbackPopup={setShowUserFeedbackPopup} />
      {
       loading ?
        <NetworkListShimmer/> 
       : <NetworkListContainer {...networkListData} />
      }
    </div>
    {isShareCard ? <UserFeedbackPopup showUserFeedbackPopup={showUserFeedbackPopup} 
      setShowUserFeedbackPopup={setShowUserFeedbackPopup} isLive={isLive} productionUrl={productionUrl} screen={'Networks List - Webview'}
      headerText={`${Constants?.NETWORK_NAME_PLURAL} List`} /> : null }
    </>
  )
}

export default NetworkListIndex
